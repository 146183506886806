<template>
    <div class="Shifts" :class="{ 'is-loading': isFetchingData }">
        <Toolbar>
            <div>
                <Dropdown
                    hasSearch
                    placeholder="Fahrer"
                    :selected="selectedEmployee"
                    :items="drivers.filter(d => d.isVisible)"
                    @onItemSelect="handleEmployeeSelect"
                />
                <Dropdown
                    hasSearch
                    :selected="selectedCar"
                    :items="cars"
                    placeholder="Kennzeichen"
                    @onItemSelect="handleCarSelect"
                />

                <Datepicker
                    @onChange="handleDateChange"
                    :startDate="selectedFrom"
                    :endDate="selectedTo"
                />
                <Button size="small" @onClick="handleQuery" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
                <div class="ToolbarActions--Group">
                    <Button
                        v-if="selectedCar || selectedEmployee"
                        v-tooltip="'In Umsatz Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('revenue')"
                    >
                        <i class="ri-money-dollar-circle-line"></i>
                    </Button>
                    <Button
                        v-if="selectedCar"
                        v-tooltip="'In Einzelfahrten Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('trips')"
                    >
                        <i class="ri-steering-2-line"></i>
                    </Button>
                    <Button
                        v-if="selectedEmployee"
                        v-tooltip="'In Arbeitszeiten Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('working-time')"
                    >
                        <i class="ri-time-line"></i>
                    </Button>
                    <Button
                        v-if="selectedCar"
                        v-tooltip="'In Betriebsnachweise Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('operationride')"
                    >
                        <i class="ri-car-line"></i>
                    </Button>
                </div>
            </div>
            <div>
                <Download
                    title="Download PDF"
                    type="pdf"
                    :options="[
                        { label: 'Einfach', value: 'simplified' },
                        { label: 'Detailliert', value: 'detailed', selected: true },
                    ]"
                    @onDownload="handleDownload"
                />
                <Checkbox
                    v-if="isMobile"
                    size="small"
                    keyName="includeWrongTrips"
                    name="includeWrongTrips"
                    :value="includeWrongTrips"
                    :direction="isMobile ? 'vertical' : 'horizontal'"
                    @onCheck="handleIncludeWrongTrips"
                >
                    Filter: Falschanmeldungen
                </Checkbox>
                <Checkbox
                    v-if="isMobile"
                    size="small"
                    keyName="showAllTrips"
                    name="showAllTrips"
                    :value="showAllTrips"
                    :direction="isMobile ? 'vertical' : 'horizontal'"
                    @onCheck="handleShowAllTrips"
                >
                    Alle Fahrten anzeigen
                </Checkbox>
            </div>
        </Toolbar>
        <Toolbar v-if="!isMobile">
            <div style="display: flex; gap:4px">
                <Checkbox
                    size="small"
                    keyName="includeWrongTrips"
                    name="includeWrongTrips"
                    :value="includeWrongTrips"
                    :direction="isMobile ? 'vertical' : 'horizontal'"
                    @onCheck="handleIncludeWrongTrips"
                >
                    Filter: Falschanmeldungen
                </Checkbox>
                <Checkbox
                    size="small"
                    keyName="showAllTrips"
                    name="showAllTrips"
                    :value="showAllTrips"
                    :direction="isMobile ? 'vertical' : 'horizontal'"
                    @onCheck="handleShowAllTrips"
                >
                    Alle Fahrten anzeigen
                </Checkbox>
            </div>
        </Toolbar>
        <div class="ShiftCards">
            <Card class="ShiftCard">
                <p>Schichtanzahl</p>
                <h3>{{ shiftCount }} {{ shiftCount === 1 ? 'Schicht' : 'Schichten' }}</h3>
            </Card>

            <Card class="ShiftCard">
                <p>Umsatz der Schichten</p>
                <h3>{{ shiftSum }}</h3>
            </Card>

            <Card class="ShiftCard">
                <p>Fahrten mit Zuschläge</p>
                <h3>{{ finalExtrasAmountCount.count }}</h3>
            </Card>
            <Card class="ShiftCard">
                <p>Summe der Zuschläge</p>
                <h3>{{ round2d(finalExtrasAmountCount.amount / 100).format() }}€</h3>
            </Card>
            <Card class="ShiftCard">
                <p>Bargeld-Zahlungen</p>
                <h3>
                    {{ priceToEuroString(totalCashPayments.amount) }}
                </h3>
            </Card>

            <Card class="ShiftCard">
                <p>
                    Unbar-Zahlungen
                    <i
                        v-if="totalNonCashPayments.amount"
                        style="font-size: 1rem"
                        class="ri-information-line"
                        v-tooltip.left="tooltipContent"
                    ></i>
                </p>
                <h3>
                    {{ priceToEuroString(totalNonCashPayments.amount) }}
                </h3>
            </Card>
        </div>

        <ShiftsTable
            :shifts="filteredShifts || shifts"
            :showAllTrips="showAllTrips"
            @onRowHover="handleRowHover"
            @onDriverClick="handleDriverClick"
            @onRowLeave="handleRowLeave"
            @onTripClick="handleTripClick"
            @onHeaderClick="handleHeaderClick"
        />
        <Modal
            :title="selectedShift && `Markieren Sie die Fahrt von\n${selectedShift.driver.name}`"
            :show="modal.active"
            :action="{ text: 'Speichern', color: 'green', callback: saveTripType }"
            :cancel="{ text: 'Abbrechen' }"
            :isLoading="isSavingTripType"
            @onModalClose="handleModalClose"
        >
            <b>
                Zahlungsart
            </b>
            <div v-for="(tripType, index) in tripTypes" :key="index" class="RadioInput">
                <input
                    type="radio"
                    :id="tripType.key"
                    :value="tripType.key"
                    :checked="tripTypeIsChecked(tripType)"
                    @click="handleTripTypeSelect"
                    @focus="handleFocus"
                    @blur="handleBlur"
                    name="tripTypeSelection"
                />
                <label :for="tripType.key">{{ tripType.label }}</label>
            </div>

            <div>
                <br />
                <label for="paymentAmount">Erhaltener Betrag</label>
                <br />
                <span class="Hint"> Maximalbetrag: </span>
                <span class="Hint">{{
                    selectedTrip ? `${round2d(selectedTrip.totalAmount / 100).format()}€` : ''
                }}</span>
                <br />
                <Input
                    type="text"
                    pattern="[0-9]+([,][0-9]+)?"
                    id="paymentAmount"
                    name="paymentAmount"
                    keyName="paymentAmount"
                    :value="wrongTripAmount"
                    @onKeyPress="handlePaymentAmountChange"
                />
                <span class="Hint"> Schnellwahl: </span>
                <div style="display: flex; gap: 5px; ">
                    <button class="Tag" @click="wrongTripAmount = 0">0 €</button>
                    <button
                        class="Tag"
                        @click="wrongTripAmount = round2d(selectedTrip.totalAmount / 100).format()"
                    >
                        {{
                            selectedTrip
                                ? `${round2d(selectedTrip.totalAmount / 100).format()}€`
                                : ''
                        }}
                    </button>
                </div>
            </div>
        </Modal>

        <Modal
            title="Fahrer ändern"
            :show="showDriverChangeModal"
            :action="{ text: 'Speichern', color: 'green', callback: handleDriverChange }"
            :cancel="{ text: 'Abbrechen' }"
            :isLoading="isChangingDriver"
            @onModalClose="showDriverChangeModal = false"
        >
            <p>
                Neuen Fahrer für Schicht auswählen:
            </p>
            <br />
            <Dropdown
                hasSearch
                placeholder="Fahrer"
                :selected="selectedDriver"
                :items="drivers.filter(d => d.isVisible)"
                @onItemSelect="handleDriverSelect"
            />
            <br />
            <br />
        </Modal>
    </div>
</template>

<script>
import axios from 'axios';
import {
    startOfDay,
    endOfDay,
    subDays,
    startOfMonth,
    formatISO,
    endOfMonth,
    format,
} from 'date-fns';
import { priceToEuroString, round2d } from '@/lib/helper';

import Datepicker from '@/components/widgets/Datepicker';
import Input from '@/components/widgets/Input';
import Button from '@/components/widgets/Button';
import Download from '@/components/widgets/Download';
import Checkbox from '@/components/widgets/Checkbox';
import Dropdown from '@/components/widgets/Dropdown';
import Headline from '@/components/Headline';
// import Infobox from '@/components/widgets/Infobox';
import Modal from '@/components/widgets/Modal';
import Toolbar from '@/components/Toolbar';

import ShiftsTable from './components/ShiftsTable';
import Card from '@/components/Card.vue';

export default {
    name: 'Shifts',
    components: {
        Card,
        ShiftsTable,
        Datepicker,
        Button,
        Download,
        Dropdown,
        Checkbox,
        Input,
        Headline,
        // Infobox,
        Modal,
        Toolbar,
    },
    data() {
        return {
            isMobile: window.innerWidth < 768,
            round2d,
            focusedInput: null,
            includeWrongTrips: false,
            includeFailedTrips: false,
            showAllTrips: false,
            shiftSum: 0,
            wrongTripAmount: 0,
            showDriverChangeModal: false,
            selectedDriver: null,
            isFetchingData: false,
            isChangingDriver: false,
            isSavingTripType: false,
            activeRow: -1,
            activeBar: -1,
            shifts: [],
            drivers: JSON.parse(localStorage.getItem('drivers')),
            cars: JSON.parse(localStorage.getItem('cars')),
            shiftIndex: null,
            tripIndex: null,
            tripTypes: [
                { label: 'Barzahlung', key: 'cash' },
                { label: 'Uber', key: 'uber' },
                { label: 'Bolt', key: 'bolt' },
                { label: 'Bliq', key: 'bliq' },
                { label: 'FreeNow', key: 'freenow' },
                { label: 'Coupon', key: 'coupon' },
                { label: 'taxi.eu Payment', key: 'taxieupayment' },
                { label: 'Kartenzahlung', key: 'card' },
                { label: 'Rechnung', key: 'invoice' },
            ],
            filteredShifts: [],
            selectedEmployee: null,
            selectedCar: null,
            selectedFrom: startOfMonth(new Date()),
            selectedTo: endOfDay(new Date()),
            selectedShift: null,
            selectedTrip: null,
            selectedTripType: null,
            modal: {
                active: false,
            },
        };
    },
    computed: {
        totalCashPayments() {
            return this.shifts.reduce(
                (acc, shift) => {
                    const filteredTrips = shift.trips.filter(trip => trip.paymentMethod === 'cash');
                    const totalAmount = filteredTrips.reduce(
                        (sum, trip) => sum + trip.totalAmount,
                        0,
                    );

                    acc.count += filteredTrips.length;
                    acc.amount += totalAmount;

                    return acc;
                },
                { count: 0, amount: 0 },
            );
        },

        totalNonCashPayments() {
            const methodsCount = {};
            let totalNonCashAmount = 0;

            this.shifts.forEach(shift => {
                shift.trips.forEach(trip => {
                    if (trip.paymentMethod !== 'cash') {
                        if (!methodsCount[trip.paymentMethod]) {
                            methodsCount[trip.paymentMethod] = { count: 0, amount: 0 };
                        }
                        methodsCount[trip.paymentMethod].count++;
                        methodsCount[trip.paymentMethod].amount += trip.totalAmount;
                        totalNonCashAmount += trip.totalAmount;
                    }
                });
            });
            console.log(methodsCount);
            return {
                count: Object.values(methodsCount).reduce((sum, method) => sum + method.count, 0),
                amount: totalNonCashAmount,
                methodsCount,
            };
        },

        tooltipContent() {
            const { methodsCount } = this.totalNonCashPayments;

            return Object.entries(methodsCount)
                .map(
                    ([method, { count, amount }]) =>
                        `${method}: ${count} ${
                            count === 1 ? 'Fahrt' : 'Fahrten'
                        } - ${priceToEuroString(amount)}`,
                )
                .join('<br>');
        },

        shiftCount() {
            return this.shifts.length;
        },
        finalExtrasAmountCount() {
            return this.shifts.reduce(
                (acc, shift) => {
                    const { count, amount } = shift.trips.reduce(
                        (tripAcc, trip) => {
                            const finalExtrasAmount = Number(trip.finalExtrasAmount);
                            return {
                                count: tripAcc.count + (finalExtrasAmount > 0 ? 1 : 0),
                                amount: tripAcc.amount + finalExtrasAmount,
                            };
                        },
                        { count: 0, amount: 0 },
                    );

                    return {
                        count: acc.count + count,
                        amount: acc.amount + amount,
                    };
                },
                { count: 0, amount: 0 },
            );
        },
    },
    methods: {
        priceToEuroString,
        handleShowOnPage(path) {
            const { selectedFrom, selectedTo, selectedCar, selectedEmployee } = this;
            const params = {
                from: format(selectedFrom, 'yyyy-MM-dd'),
                to: format(selectedTo, 'yyyy-MM-dd'),
                car: selectedCar?.value,
                driver: selectedEmployee?.value,
            };
            this.$router.push({ name: path, query: params });
        },
        handleFocus(e) {
            this.focusedInput = e.target;

            this.focusedInput.parentElement.classList.add('has-focus');
        },
        handleBlur(e) {
            this.focusedInput.parentElement.classList.remove('has-focus');
            this.focusedInput = null;
        },
        handleShowAllTrips(checked) {
            this.showAllTrips = checked;
            if (this.includeWrongTrips) {
                this.filteredShifts = this.shifts.filter(
                    s => s.driver.driverNumber == s.driver.name,
                );
            }
        },
        handleIncludeFailedTrips(checked) {
            this.includeFailedTrips = checked;
            const url = new URL(window.location.href);
            url.searchParams.delete('incorrect');
            url.searchParams.delete('startDate');
            const hasWarning = entry => {
                return entry?.subtractedTotalAmount !== 0 || !entry.driver?.name;
            };
            window.history.replaceState({}, '', url);
            this.filteredShifts = checked ? this.shifts.filter(s => hasWarning(s)) : this.shifts;
        },
        handleIncludeWrongTrips(checked) {
            this.includeWrongTrips = checked;

            const url = new URL(window.location.href);
            url.searchParams.delete('incorrect');
            url.searchParams.delete('startDate');

            window.history.replaceState({}, '', url);
            this.filteredShifts = checked
                ? this.shifts.filter(s => s.driver.driverNumber == s.driver.name)
                : this.shifts;
        },
        handlePaymentAmountChange({ paymentAmount }) {
            const value = paymentAmount.replace(/[^0-9,]/g, '');
            this.wrongTripAmount = value;
        },

        handleTripTypeSelect({ target: { value } }) {
            this.selectedTripType = value;
        },
        setTrips(trips) {
            return trips || [];
        },
        setActiveBar(i) {
            this.activeBar = i;
        },
        dataset() {
            let arr = [];
            for (let i = 0; i < 31; i++) {
                arr.push(Math.floor(Math.random() * 400));
            }
            return arr;
        },
        handleModalClose() {
            this.resetTripSelection();
            this.modal.active = false;
        },
        handleHeaderClick(props) {},
        handleTripClick({ i: shiftIndex, j: tripIndex }) {
            this.selectedShift = this.shifts[shiftIndex];
            const driverName = this.selectedShift?.driver?.name;
            this.selectedTrip = {
                ...this.selectedShift.trips[tripIndex - 1],
                driver: { name: driverName },
            };
            this.wrongTripAmount = this.selectedTrip.finalTotalAmount
                ? round2d(this.selectedTrip.finalTotalAmount / 100).format()
                : 0;
            this.modal.active = true;
        },
        handleEmployeeSelect({ item }) {
            this.selectedEmployee = item;
        },
        handleCarSelect({ item }) {
            this.selectedCar = item;
        },
        async saveTripType() {
            try {
                console.log('Saving trip type', this.selectedTrip);
                this.isSavingTripType = true;
                const tripId = this.selectedTrip.uuid;
                const paymentMethod = this.selectedTripType || this.selectedTrip.paymentMethod;
                console.log('Payment method', paymentMethod);
                const newAmount = Math.round(
                    Number(String(this.wrongTripAmount).replace(',', '.')) * 100,
                );

                if (newAmount < 0) {
                    this.$toasted.show('Der Betrag darf nicht negativ sein.', { type: 'error' });
                    return;
                }

                if (newAmount !== this.selectedTrip.finalTotalAmount) {
                    if (newAmount > this.selectedTrip.totalAmount) {
                        this.$toasted.show(
                            'Der Betrag darf nicht höher sein als der Gesamtbetrag.',
                            { type: 'error' },
                        );
                        return;
                    }
                    const urlPaymentAmount = `${process.env.VUE_APP_API_BASE_URL}/cpanel/trips/${tripId}/payment_amount`;
                    await axios.patch(
                        urlPaymentAmount,
                        { paymentAmount: newAmount },
                        { withCredentials: true },
                    );
                }

                if (paymentMethod !== 'wrong') {
                    const urlPaymentMethod = `${process.env.VUE_APP_API_BASE_URL}/cpanel/trips/${tripId}/payment_method`;
                    await axios.patch(
                        urlPaymentMethod,
                        { paymentMethod },
                        { withCredentials: true },
                    );
                }

                this.resetTripSelection();
            } catch (error) {
                console.error(error);
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isSavingTripType = false;
                this.modal.active = false;
                this.handleQuery();
            }
        },
        resetTripSelection() {
            this.selectedShift = null;
            this.selectedTrip = null;
            this.selectedTripType = null;
        },
        handleDriverSelect({ item }) {
            this.selectedDriver = item;
        },
        handleDownload(_, option) {
            const year = this.selectedFrom.getFullYear();
            const month = this.selectedFrom.getMonth() + 1;
            const driver = this.selectedEmployee ? this.selectedEmployee.id : 0;
            const license = this.selectedCar ? this.selectedCar.id : 0;
            const type = option === 'simplified' ? '/simplified' : '';
            const url = `https://api.app.taxifusion.de/cpanel/shifts/${year}/${month}/driver/${driver}/license/${license}/pdf${type}`;

            window.open(url, '_blank');
        },
        handleDateChange(dateRange) {
            this.selectedFrom = dateRange.from || this.selectedFrom || startOfMonth(new Date());
            this.selectedTo = dateRange.to || this.selectedFrom;
        },
        handleRowHover(index) {
            this.activeRow = index;
        },
        handleRowLeave() {
            this.activeRow = -1;
        },
        handleDriverClick(id) {
            this.selectedShift = this.shifts.find(s => s.uuid === id);
            this.showDriverChangeModal = true;
        },
        tripTypeIsChecked(tripType) {
            if (!this.selectedTrip) return false;

            return tripType.key === this.selectedTrip.paymentMethod;
        },
        async handleDriverChange() {
            this.isChangingDriver = true;
            const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/shifts/${this.selectedShift.uuid}/driver`;
            const res = await axios
                .patch(url, { driverNumber: this.selectedDriver.id }, { withCredentials: true })
                .catch(() => {
                    this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
                })
                .finally(() => {
                    this.isChangingDriver = false;
                });

            this.$toasted.show('Fahrer erfolgreich geändert.', { type: 'success' });
            this.showDriverChangeModal = false;
            this.handleQuery();
        },
        async handleQuery() {
            this.isFetchingData = true;
            const queryData = {
                driverNumber: this.selectedEmployee ? this.selectedEmployee.id : null,
                licenseNumber: this.selectedCar ? this.selectedCar.id : null,
                rangeStartAt: formatISO(this.selectedFrom),
                rangeEndAt: this.selectedTo ? formatISO(endOfDay(this.selectedTo)) : null,
            };
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/shifts`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });
                this.shiftSum = result.data.reduce(
                    (acc, { finalTotalAmount }) => (acc += finalTotalAmount),
                    0,
                );
                this.$emit('onFinishLoading');
                this.shiftSum = `${round2d(this.shiftSum / 100).format()}€`;
                this.shifts = result.data;

                this.filteredShifts = this.includeWrongTrips
                    ? this.shifts.filter(s => s.driver.driverNumber == s.driver.name)
                    : this.shifts;
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
    },
    mounted() {
        // Extract query parameters from the URL using Vue Router's $route.query
        const { incorrect, driverNumber, licenseNumber, from, to, car, driver } = this.$route.query;
        this.includeWrongTrips = incorrect === 'true';
        this.selectedFrom = from ? new Date(from) : startOfMonth(new Date());
        this.selectedTo = to
            ? new Date(to)
            : to
            ? endOfMonth(new Date(from))
            : endOfDay(new Date());

        this.selectedCar = car
            ? this.cars.find(c => c.value === car)
            : licenseNumber
            ? this.cars.find(c => c.id == licenseNumber)
            : null;

        this.selectedEmployee = driver
            ? this.drivers.find(d => d.value === driver)
            : driverNumber
            ? this.drivers.find(d => d.id == driverNumber)
            : null;

        setTimeout(() => {
            this.handleQuery();
        }, 100);
    },
};
</script>

<style lang="scss">
.Shifts {
    @extend %contentWrapper;
    @extend %page;

    .ShiftCards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 4px;
        margin: 20px 0;
    }

    .ShiftCard {
        padding: 10px;

        h3 {
            font-size: 1rem;
            font-weight: 700;
        }

        p {
            font-size: 0.75rem;
        }
    }

    .RadioInput {
        font-size: 1rem;
        padding: 2px;
        margin-left: 20px;
        display: flex;
        width: fit-content;
        align-items: center;
        gap: 8px;

        &.has-focus {
            outline: solid 1px var(--color-blue-dark);
        }

        input {
            margin: 0;
        }
    }

    .Tag {
        border: 1px solid var(--color-blue-light);
        background-color: var(--color-bg);
        color: color(blue-dark);
        padding: 4px 10px;
        border-radius: 40px;
        cursor: pointer;
        font-size: 14px;
        min-width: 50px;
        display: inline-block;
        text-align: center;

        &:hover {
            border-color: var(--color-blue-dark);
            background-color: rgba(0, 0, 0, 0.1);
            color: var(--color-blue-light);
        }

        &:focus {
            box-shadow: 0px 0px 0px 4px var(--color-blue-dark);
            outline: solid 2px white;
        }
    }
}
</style>
